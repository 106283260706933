import store from "@/store";
import moment from "moment";
const currencySymbolObject = {
  USD: "$",
  CAD: "$",
  AUD: "$",
  INR: "₹" || "&#8377;",
  EUR: "€",
};
const typeMapping = {
  SINGLE_LINE_TEXT: "string",
  RICH_TEXT: "string",
  EMAIL: "string",
  MULTI_LINE_TEXT: "string",
  SELECT: "string",
  RADIO: "string",
  LIST: "string",
  FORMULA: "string",
  HYPER_LINK: "string",
  AGGREGATE_FUNCTION: "string",
  PASSWORD: "string",
  PHONE: "string",
  FAX: "string",
  CURRENCY: "string",
  ZIP_CODE: "string",
  LOCATION: "string",
  ENTITY: "string",
  PAYMENT_VARIABLE: "string",
  ENTITY_VARIABLE: "string",
  QUESTION: "string",
  AUTO_INCREMENT_NUMBER: "string",
  WEEKDAYS: "string",
  PHONE_COUNTRY_CODE: "string",
  MASKED: "string",
  ADDRESS: "string",
  CONCATENATE: "string",
  MULTI_SELECT: "array",
  DATE_TIME_RANGE: "array",
  TIME_RANGE: "array",
  DATE_RANGE: "array",
  ENTITY_TABLE: "array",
  DATA_TABLE: "array",
  NUMBER: "number",
  YES_OR_NO: "boolean",
  CHECKBOX: "boolean",
  DATE: "date",
  TIME: "date",
  DATE_TIME: "date",
  DATE_SIGNED: "date",
  "*": "string",
};
export default {
  name: "Fields Format Helper",
  data() {
    return {
      fieldInputTypeKeyLabels: {
        PHONE_COUNTRY_CODE: "Phone Country Code",
        SINGLE_LINE_TEXT: "Single Line Text",
        RICH_TEXT: "Rich Text",
        EMAIL: "Email",
        MULTI_LINE_TEXT: "Multi Line Text",
        NUMBER: "Number",
        SELECT: "Select",
        MULTI_SELECT: "Multi Select",
        YES_OR_NO: "Yes or No",
        FILE: "File",
        DATE: "Date",
        TIME: "Time",
        DATE_TIME: "Date Time",
        DATE_TIME_RANGE: "Date Time Range",
        RADIO: "Radio",
        RADIO_BUTTON_GROUP: "Radio Button Group",
        COMPANY_DOCUMENT: "Company Document",
        HEADING: "Heading",
        SIGNATURE: "Signature",
        CHECKBOX: "Checkbox",
        CHECKBOX_GROUP: "Checkbox Group",
        GLOBAL_VARIABLE: "Global Variable",
        ACTION_BUTTON: "Action Button",
        HORIZONTAL_DIVIDER: "Horizontal Divider",
        DATE_SIGNED: "Date Signed",
        IMAGE: "Image",
        SINGLE_LINE_CONTENT: "Single Line Content",
        PARAGRAPH: "Paragraph",
        HTML_CONTENT: "HTML Content",
        TABLE: "Table",
        AUTHORIZED_SIGNATURE: "Authorized Signature",
        FORMULA: "Formula",
        ENTITY_VARIABLE: "Entity Variable",
        LIST: "List",
        HORIZONTAL_LINE: "Horizontal Line",
        DIVISION: "Division",
        VIDEO: "Video",
        AGGREGATE_FUNCTION: "Aggregate Function",
        PASSWORD: "Password",
        PHONE: "Phone",
        ZIP_CODE: "Zip code",
        FAX: "Fax",
        CURRENCY: "Currency",
        LOCATION: "Location",
        ENTITY: "Entity",
        QUESTION: "Question",
        AUTO_INCREMENT_NUMBER: "Auto Increment Number",
        WEEKDAYS: "Weekdays",
        ADDRESS: "Address",
        TIME_RANGE: "Time Range",
        CHECKOUT_BUTTON: "Checkout Button",
        DATA_TABLE: "Data Table",
        MASKED: "Masked",
        PAY_BUTTON: "Pay Button",
        STAR_RATING: "Star Rating",
        DATE_RANGE: "Date Range",
        RECEIVE_BUTTON: "Receive Button",
        HYPER_LINK: "Hyper Link",
        PAYMENT_VARIABLE: "Payment Variable",
        CONCATENATE: "Concatenate",
        ENTITY_TABLE: "Entity Table",
        AUDIO: "Audio",
        TIMER: "Timer",
        CAPTCHA: "Captcha",
        QR: "QR",
        QR_READER: "QR Reader",
        GIF: "GIF",
      },
    };
  },
  methods: {
    getFormattedFieldData(field, form) {
      if (!form || !field ||form[field.key] == null || form[field.key] == undefined) {
        return "-";
      }
      let inputType = field.input_type;
      if (field.input_type == "FORMULA") {
        if (field.validations?.currency) {
          inputType = "CURRENCY";
        } else if (field?.result_type) {
          inputType = field.result_type;
        }
      } else if (
        field.input_type == "PAYMENT_VARIABLE" ||
        field.input_type == "ENTITY_VARIABLE"
      ) {
        inputType = field.inputType;
      }
      let time1, time2, returnValue, currencyType, formattedValue;
      switch (inputType) {
        case "ENTITY":
          returnValue = form[`${field.key}/name`];
          break;
        case "DATE":
          returnValue = this.getFormattedDate(form[field.key]) || "-";
          break;
        case "TIME":
          returnValue = this.getFormattedTime(form[field.key]) || "-";
          break;
        case "DATE_TIME":
          returnValue = this.getFormattedDateTime(form[field.key]) || "-";
          break;
        case "TIME_RANGE":
          time1 = this.getFormattedTime(form[field.key][0]) || "-";
          time2 = this.getFormattedTime(form[field.key][1]) || "-";
          returnValue = `${time1} - ${time2}`;
          break;
        case "DATE_RANGE":
          time1 = this.getFormattedDate(form[field.key][0]) || "-";
          time2 = this.getFormattedDate(form[field.key][1]) || "-";
          returnValue = `${time1} to ${time2}`;
          break;
        case "DATE_TIME_RANGE":
          time1 = this.getFormattedDateTime(form[field.key][0]) || "-";
          time2 = this.getFormattedDateTime(form[field.key][1]) || "-";
          returnValue = `${time1} to ${time2}`;
          break;
          case "CURRENCY":{
            currencyType = field.validations?.currency
                ? field.validations.currency
                : field.selected_fields[0].validations?.currency;
            if (!Number.isInteger(form[field.key])) {
                formattedValue = Number(form[field.key])?.toFixed(2);
            } else {
                formattedValue = form[field.key];
            }
            const currencyLocaleMap = {
                USD: "en-US",
                EUR: "en-IE",
                INR: "en-IN",
                CAD: "en-CA",
                AUD: "en-AU"
            };
            const locale = currencyLocaleMap[currencyType];
            formattedValue = new Intl.NumberFormat(locale).format(formattedValue);
            returnValue = `${currencySymbolObject[currencyType]}${formattedValue}`;

            break;
        }
        case "LIST":
          if (field.list_data && field.list_data.length) {
            let data = field.list_data.find((e) => e.value == form[field.key]);
            returnValue = data.name;
          }
          break;
        case "NUMBER":
          returnValue = form[field.key];
          if (field?.properties?.number_type == "DECIMAL") {
            returnValue = parseFloat(form[field.key])?.toFixed(2);
          } else if (field.decimals) {
            returnValue = parseFloat(form[field.key])?.toFixed(field.decimals);
          } else if (!Number.isInteger(form[field.key])) {
            returnValue = parseFloat(form[field.key])?.toFixed(2);
          }
          break;
        case "FORMULA":
          returnValue = parseFloat(form[field.key])?.toFixed(2);
          break;
        case "AGGREGATE_FUNCTION":
          returnValue = this.getDataFieldValue(field, form[field.key]);
          break;
        case "PHONE_COUNTRY_CODE":
          returnValue = form[`${field.key}_code`]["formatInternational"];
          break;
        default:
          returnValue = form[field.key];
          break;
      }
      return returnValue;
    },
    getFormattedDateTime(date) {
      if (!date) return "";
      const format = store.getters["auth/getDefaultDateFormat"]
        ? store.getters["auth/getDefaultDateFormat"] + " HH:mm:ss"
        : +"MM-DD-YYYY HH:mm";
      return moment(date, moment.ISO_8601, true).isValid()
        ? moment(date).format(format)
        : date;
    },
    getFormattedTime(date) {
      if (!date) return "";
      const format = store.getters["auth/getDefaultDateFormat"]
        ? " HH:mm:ss"
        : +"MM-DD-YYYY HH:mm:ss";
      return moment(date, moment.ISO_8601, true).isValid()
        ? moment(date).format(format)
        : date;
    },
    getFormattedDate(date) {
      if (!date) return "";
      const format = store.getters["auth/getDefaultDateFormat"]
        ? store.getters["auth/getDefaultDateFormat"]
        : "MM-DD-YYYY";
      return moment(date, moment.ISO_8601, true).isValid()
        ? moment(date).format(format)
        : date;
    },
    getDataFieldValue(field, value) {
      if (!value) {
        value = 0;
      }
      if (field?.properties?.number_type == "DECIMAL") {
        value = parseFloat(value)?.toFixed(2);
      } else if (field.decimals) {
        value = parseFloat(value)?.toFixed(field.decimals);
      } else if (!Number.isInteger(value)) {
        value = parseFloat(value)?.toFixed(2);
      }
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        value = `${currencySymbolObject[type]}${value}`;
      } else if (
        field?.inputType == "AGGREGATE_FUNCTION" ||
        field?.inputType == "FORMULA"
      ) {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (
            el?.inputType == "CURRENCY" ||
            el?.input_type == "CURRENCY" ||
            el?.result_type == "CURRENCY" ||
            Object.keys(currencySymbolObject)?.includes(el.currency_type) 
          ) {
            return true;
          }
        });
        if (isCurrencyField) {
          if (field?.inputType == "AGGREGATE_FUNCTION") {
            // let dataTableField = this.getField(
            //   field.template_id + "#" + isCurrencyField.data_table_key
            // );
            // if (dataTableField) {
            //   let rField = (dataTableField.data_table_columns || []).find(
            //     (e) => e.key == isCurrencyField.key
            //   );
            //   if (rField?.validations) {
            //     isCurrencyField["validations"] = rField.validations;
            //   }
            // }
          } else {
            if (field?.validations) {
              isCurrencyField["validations"] = field.validations;
            }
          }
          let type = isCurrencyField?.validations?.currency || "USD";
          value = `${currencySymbolObject[type]}${value}`;
        }
      }
      return value;
    },
    getParsedHtmlString(form, string, fields) {
      if (!string || !fields?.length) {
        return "";
      }
      let keyAndFields = {};
      fields.map((e) => {
        keyAndFields[e.key] = e;
      });
      string = string.replace(/\[\[(.*?)\]\]/g, (match, key) => {
        const template = keyAndFields[key];
        if (!template) {
          return "-";
        }
        let value = this.getFormattedFieldData(template, form);
        return value ?? "-";
      });
      return string;
    },
    getOutputType(inputType) {
      return typeMapping[inputType] || typeMapping["*"];
    }
  },
};
